<template>
  <!--  职称管理-->
  <div class="titleManagement">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div style="overflow: hidden;display: flex;justify-content: space-between">
    <div class="menu_select">
      <a-menu
          style="width: 100%"
          mode="inline"
          v-model="menu"
      >
        <a-menu-item
            v-for="item in typeInfo"
            :key="item.value"
            @click="leftChange(item.value)"
        >
          {{item.label}}
        </a-menu-item>
      </a-menu>
    </div>
    <div class="InfoTable">
      <div class="select_Info">
        <div>
          名称:  &nbsp;<a-input v-model="searchName" placeholder="输入名称搜索" style="width: 180px" size="default" />&nbsp;&nbsp;&nbsp;
          <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
          <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button>
        </div>
      </div>
      <div class="table_Info">
        <a-button type="primary" @click="()=>this.addShow = true">新增</a-button>
        <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" rowKey="id"   :pagination="false"
        >
          <span slot="operate" slot-scope="text,item">
          <a style="margin-right: 10px" @click="editBtn(item)"> 修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delTitle(item.id)"
              @cancel="cancel"
          >
            <a >删除</a>
            </a-popconfirm>
        </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="paginationChange" />
        </div>
      </div>
    </div>
      <a-modal v-model="addShow" title="新增" @ok="addOk" >
        <a-form-model :model="addForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="add">
          <a-form-model-item prop="identityNo" label="身份">
            <a-select v-model="addForm.identityNo"  placeholder="选择身份" @change="typeChange">
              <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.value">
                {{ list.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="jobTitle" label="职称">
            <a-input v-model="addForm.jobTitle" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal v-model="editShow" title="修改" @ok="editOk" >
        <a-form-model :model="editForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="edit">
          <a-form-model-item prop="identityNo" label="身份">
            <a-select v-model="editForm.identityNo"  placeholder="选择身份" @change="typeEdit">
              <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.value">
                {{ list.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="jobTitle" label="职称">
            <a-input v-model="editForm.jobTitle" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {delTitle, getTitle, postTitle, putTitle} from "@/service/dataDictionary_api";

export default {
  name: "titleManagement",
  data(){
    return{
      addShow:false,
      editShow:false,
      spinning:false,
      editForm:{},
      addForm:{},
      rules:{
        identityNo: [
          { required: true, message: '请选择身份', trigger: 'blur' }],
        jobTitle: [
          { required: true, message: '请输入职称', trigger: 'blur' }],
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      searchName:'',
      columns:[
        {
          title: '职称',
          dataIndex: 'jobTitle',
        },
        {
          title: '身份',
          dataIndex: 'identityNo',
        },
        {
          title: '提交时间',
          dataIndex: 'createdTime',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      menu:[1001],
      type: 1001,
      typeInfo:[
        {
          value:1001,
          label:'医生'
        },
        {
          value:1002,
          label:'护士'
        },
        {
          value:1003,
          label:'医技'
        },
        {
          value:1004,
          label:'药师'
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"职称管理")
  },
   mounted() {
    this.getTitleList(1001)
  },
  methods:{
    async getTitleList(identify,pageNum,title){
      this.spinning = true
      const Info =await getTitle(identify,pageNum,title)
      if (Info.code ===0){
        this.InfoTable = Info.data.row
        this.pagination.total = Info.data.count
      }
      this.spinning =false
    },
    //左侧身份选择
    leftChange(value){
      this.type = value
      this.getTitleList(value,1)
      this.pagination.current = 1
    },
    //分页跳转
    paginationChange(e){
      this.getTitleList(this.type,e,this.searchName)
    },
    //搜索
    searchOk(){
      this.getTitleList(this.type,'',this.searchName)
      this.pagination.current = 1
    },
    //搜索重置
    searchReset(){
      this.getTitleList(this.type,1)
      this.searchName = ''
      this.pagination.current = 1
    },
    //新增确认
    async addOk(){
      this.$refs.add.validate(async valid =>{
        if (valid){
          const data = {
            jobTitle : this.addForm.jobTitle,
            identityNo : this.addForm.identityNo
          }
          const Info =await postTitle(data)
          if (Info.code === 0 ){
            this.$message.success('添加成功')
            await this.getTitleList(this.type,this.pagination.current)
            this.$refs.add.resetFields();
            this.addForm = {}
            this.addShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    editBtn(row){
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editShow = true
    },
    //修改职称
    async editOk(){
      this.$refs.edit.validate(async valid =>{
        if (valid){
          const data = {
            jobTitle : this.editForm.jobTitle,
            identityNo : this.editForm.identityNo
          }
          const Info =await putTitle(this.editForm.id,data)
          if (Info.code === 0 ){
            this.$message.success('修改成功')
            await this.getTitleList(this.type,this.pagination.current)
            this.$refs.edit.resetFields();
            this.editForm = {}
            this.editShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除职称
    async delTitle(id){
      const Info =await delTitle(id)
      if (Info.code === 0){
       await this.getTitleList(this.type,1)
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
    //选择身份
    typeChange(e){
      this.addForm.identityNo = e
    },
    typeEdit(e){
      this.editForm.identityNo = e
    }
  },
}
</script>

<style lang="scss" scoped>
.titleManagement{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .InfoTable{
    background-color: white;
    width: 81%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .select_Info{
      display: flex;
      margin: 20px;
    }
    .table_Info{
      margin: 20px;
    }
  }
}


</style>
